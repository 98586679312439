// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SchematicQuestion from './components/schematicQuestion/schematicQuestion';
import HomePage from './components/HomePage'; // Learning Page
import Level from './components/Level';
import { LevelProvider } from './contexts/LevelContext';
import MainHomePage from './components/MainHomePage'; // New Main Home Page
import TestPage from './components/TestPage'; // Import the new TestPage component

const App = () => {
  return (
    <LevelProvider>
      <Router>
        <Routes>
          {/* Main landing page */}
          <Route path="/" element={<MainHomePage />} />

          {/* Learning page */}
          <Route path="/learn" element={<HomePage />} />

          {/* Levels and questions */}
          <Route path="/level/:levelIndex/exercise/:exerciseIndex" element={<Level />} />
          <Route path="/schematic-question/:levelId" element={<SchematicQuestion />} />

          {/* Test page */}
          <Route path="/test" element={<TestPage />} />  {/* Add the new test route */}
        </Routes>
      </Router>
    </LevelProvider>
  );
};

export default App;
