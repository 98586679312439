import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import './GridCell.css';
import rotateConnections from '../rotateConnections';

import redLightBulbOn from '../assets/red-light-bulb-on.svg';
import redLightBulbOff from '../assets/red-light-bulb-off.svg';
import redLightBulbDim from '../assets/red-light-bulb-dim.svg';
import redLightBulbExploded from '../assets/red-light-bulb-exploded.svg';

import yellowLightBulbOn from '../assets/yellow-light-bulb-on.svg';
import yellowLightBulbOff from '../assets/yellow-light-bulb-off.svg';
import yellowLightBulbDim from '../assets/yellow-light-bulb-dim.svg';
import yellowLightBulbExploded from '../assets/yellow-light-bulb-exploded.svg';

import greenLightBulbOn from '../assets/green-light-bulb-on.svg';
import greenLightBulbOff from '../assets/green-light-bulb-off.svg';
import greenLightBulbDim from '../assets/green-light-bulb-dim.svg';
import greenLightBulbExploded from '../assets/green-light-bulb-exploded.svg';

import starOff from '../assets/star-off.svg';
import starOn from '../assets/star-on.svg';

import switchOnImage from '../assets/comp-switch-on.svg';
import switchOffImage from '../assets/comp-switch-off.svg';

import componentDictionary from './componentDictionary'; // Adjust path accordingly


const getBulbImage = (type, bulbStatus) => {
  switch (type) {
    case 'red-light-bulb':
      if (bulbStatus['red-light-bulb'] === 'ON') return redLightBulbOn;
      if (bulbStatus['red-light-bulb'] === 'DIM') return redLightBulbDim; // You need a dim image asset
      if (bulbStatus['red-light-bulb'] === 'EXPLODED') return redLightBulbExploded; // You need an exploded image asset
      return redLightBulbOff;

    case 'yellow-light-bulb':
      if (bulbStatus['yellow-light-bulb'] === 'ON') return yellowLightBulbOn;
      if (bulbStatus['yellow-light-bulb'] === 'DIM') return yellowLightBulbDim;
      if (bulbStatus['yellow-light-bulb'] === 'EXPLODED') return yellowLightBulbExploded;
      return yellowLightBulbOff;

    case 'green-light-bulb':
      if (bulbStatus['green-light-bulb'] === 'ON') return greenLightBulbOn;
      if (bulbStatus['green-light-bulb'] === 'DIM') return greenLightBulbDim;
      if (bulbStatus['green-light-bulb'] === 'EXPLODED') return greenLightBulbExploded;
      return greenLightBulbOff;

    default:
      return null;
  }
};


const getStarImage = (starStatus) => {
  console.log("StarStatus", starStatus);
  return starStatus ? starOn : starOff;
};

const getBackgroundColor = (item) => {
  switch (item?.type) {
    case 'red-light-bulb':
      return '#F0C6C6'; // Light red
    case 'yellow-light-bulb':
      return '#F7E9B7'; // Light yellow
    case 'green-light-bulb':
      return '#DCEBCB'; // Light yellow
  }
  if (item?.prePlaced) {
    return '#f5f5f5'; // Grey for pre-placed items
  }
};

const GridCell = ({  row, col, item, grid, setGrid, updateGrid, removeItem, rotateItem, bulbStatus,starStatus,setItemCounts,isNextAvailable   }) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'ITEM',
    drop: (draggedItem) => {
      if (item) {
        // Check if something already exists.
        return { rejected: true };
      }
      console.log(`Item dropped: ${JSON.stringify(draggedItem)} at row: ${row}, col: ${col}`);

      const rotatedConnections = rotateConnections(draggedItem.connections, draggedItem.rotation);

      // First update the grid with the new item and remove the old item in a single state update
      updateGridAndRemoveOldItem(row, col, { ...draggedItem, connections: rotatedConnections }, draggedItem.row, draggedItem.col);
      return { rejected: false };
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'ITEM',
    item: () => {
      // Ensure row and col are correctly set in the item
      const dragItem = { ...item, row, col,rotation: item.rotation !== undefined ? item.rotation : 0 };
      //console.log("CODE ITEM run within useDrag", dragItem.row, dragItem.col);
      return dragItem;
    },
    canDrag: () => !item?.prePlaced,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (draggedItem, monitor) => {
      if (!monitor.didDrop()) {
        console.log("Drag unsuccessful", draggedItem.row, draggedItem.col);
        removeItem(draggedItem.row, draggedItem.col);
      }

    },
  });

  const updateGridAndRemoveOldItem = (newRow, newCol, newItem, oldRow, oldCol) => {
    let item = newItem
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === newRow && colIndex === newCol) {
          //console.log(`Placing item at row: ${newRow}, col: ${newCol}`);

          setItemCounts(prev => {
            const newCount = { ...prev };
            newCount[item.id] -= 1;
            //console.log(`COUNT Reduced ${item.type} (${item.id}): ${newCount[item.id]} remaining`);
            return newCount;
          });

          return { ...newItem, rotation: newItem.rotation !== undefined ? newItem.rotation : 0 };
        }
        if (rowIndex === oldRow && colIndex === oldCol) {
          //console.log(`Removing item from row: ${oldRow}, col: ${oldCol}`);


          setItemCounts(prev => {
            const newCount = { ...prev };
            newCount[item.id] += 1;
            //console.log(`COUNT Increase ${item.type} (${item.id}): ${newCount[item.id]} remaining`);
            return newCount;
          });

          return null;
        }
        return c;
      })
    );
    setGrid(newGrid);
    console.log("Updated grid state:", newGrid);
  };


  const handleSwitchToggle = () => {
    const newSwitchState = !item.isOn; // Toggle switch state
    const newConnections = newSwitchState
      ? componentDictionary['switch'].onConnections
      : componentDictionary['switch'].offConnections;

    // Update the grid with the new connections for the switch
    const newGrid = grid.map((r, rowIndex) =>
      r.map((c, colIndex) => {
        if (rowIndex === row && colIndex === col) {
          return { ...item, connections: newConnections, isOn: newSwitchState }; // Apply new connections and state
        }
        return c;
      })
    );

    setGrid(newGrid); // Set the updated grid state
  };


  const handleTouchEnd = (e) => {
    e.preventDefault();
    if (item) {
      if (item.type === 'switch') {
        handleSwitchToggle();
      }
      else {
        if(!item.prePlaced){
          rotateItem(row, col);
        }
      }
    }
  };

  //console.log(`GridCell Row: ${row}, Col: ${col}, isNextAvailable: ${isNextAvailable}`);

  // Dynamically set the style of the resistor label based on the rotation
  const getResistorLabelStyle = (rotation) => {
    switch (rotation) {
      case 0:
        // Position at the bottom center
        return {
          bottom: '0px',
          left: '50%',
          transform: 'translateX(-50%)',
        };
  
      case 90:
        // Position underneath the resistor with -90deg rotation for correct alignment
        return {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(-90deg)',
          backgroundColor: 'white', // White background for visibility
          padding: '2px 2px',
          borderRadius: '3px',
        };
  
      case 180:
        // Position at the top center, similar to case 0 but at the top
        return {
          top: '0px', // Positioned at the top
          left: '50%',
          transform: 'translateX(-50%) rotate(180deg)', // No rotation needed
        };
  
      case 270:
        // Similar to 90 degrees but rotated -90 degrees to realign the text
        return {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%) rotate(90deg)',
          backgroundColor: 'white', // White background for visibility
          padding: '2px 2px',
          borderRadius: '3px',
        };
  
      default:
        return {};
    }
  };
  

  return (
    <div
    className={`grid-cell ${item && item.prePlaced ? 'pre-placed' : ''} ${isOver ? 'hovered' : ''}`}
      ref={drop}
      onClick={() => {
        if (item) {
          if (item.type === 'switch') {
            handleSwitchToggle();
          } else {
            if(!item.prePlaced){
              rotateItem(row, col);
            }
          }
        }
      }}
      onTouchEnd={handleTouchEnd}
      
      style={{ backgroundColor: getBackgroundColor(item) }}
    >
        {/* Add the next-available indicator inside the grid-cell */}
      {isNextAvailable && <div className="next-available"></div>}
      {item && (
        <div className="grid-item" ref={drag} style={{ transform: `rotate(${item?.rotation ?? 0}deg)`, opacity: isDragging ? 0.5 : 1,transition: 'transform 0.2s ease', }}>
        {item?.type === 'switch' ? (
          <img src={item.isOn ? switchOnImage : switchOffImage} alt={`${item?.type}`} />
        ) : item?.type?.includes('light-bulb') ? (
          <img src={getBulbImage(item.type, bulbStatus)} alt={`${item.type}`} />
        ) : item?.type === 'star' ? (
          <img src={getStarImage(starStatus)} alt={`${item.type}`} />
        ) : (
          <img src={item?.svgPath} alt={`${item?.type}`} />
        )}
        {/* Add the resistor label with dynamic positioning */}
        {item?.type === 'resistor' && (
          <div className="resistance-value-grid" style={getResistorLabelStyle(item.rotation)}>
            {item.resistanceValue}Ω
          </div>
        )}
    </div>

      )}
    </div>
  );
};

export default GridCell;
