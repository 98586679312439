import componentDictionary from './componentDictionary';

const levels = [
  // Level 1 - Getting use to controls
    {exercises: [
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'straight-wire', row: 1, col: 1, rotation: 90 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: 'corner-wire', row: 1, col:0, rotation: 180 },
            { type: 'star', row: 1, col: 2, rotation: 0 },
            { type: 'corner-wire', row: 0, col:3, rotation: 0 },
            { type: 'corner-wire', row: 1, col:3, rotation: 90 },
          ],
          draggableItems: [
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(0),
              id: 0, itemCount: 1, rotation: 0,resistanceValue: 330
            },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 4,
            gridRow: 2
        }
        },
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: 'straight-wire', row: 0, col: 1, rotation: 90 },
            { type: 'straight-wire', row: 1, col: 1, rotation: 90 },
            { type: 'star', row: 1, col: 2, rotation: 0 },
            { type: 'straight-wire', row: 1, col: 3, rotation: 90 },
            { type: 'corner-wire', row: 0, col:4, rotation: 0 },
          ],
          draggableItems: [
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(0),
              id: 0, itemCount: 1, rotation: 0,resistanceValue: 330
            },
            { type: "corner-wire", id: 1,itemCount: 2,rotation: 180 },
            { type: "red-light-bulb", id: 2,itemCount: 1,rotation: 180},
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 5,
            gridRow: 2
        }
        },

        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 1, rotation: 0 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: 'yellow-light-bulb', row: 1, col: 1, rotation: 90 },
            { type: 'red-light-bulb', row: 2, col: 0, rotation: 270 },
            { type: 'corner-wire', row: 0, col: 2, rotation: 0 },
            { type: 't-wire', row: 2, col: 2, rotation: 90 },
            { type: 'star', row: 3, col: 1, rotation: 90 },
            { type: 'block', row: 3, col: 0, rotation: 90 },
            { type: 'block', row: 4, col: 0, rotation: 90 },
          ],
          draggableItems: [
            { type: "t-wire", id: 0, itemCount: 3, rotation: 270},
            { type: "straight-wire", id: 1, itemCount: 2 },
            { type: "corner-wire", id: 2, itemCount: 3,rotation: 90 },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "ON" },
            { type: 'red-light-bulb', status: "ON" },
          ],
          metadata:{
            gridCol: 3,
            gridRow: 5
        }
        },
      ]},
  // Level 2 - Introduce switch
    {exercises: [
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: "resistor",
              ...componentDictionary['resistor'].initialize(0),
              row: 0, col: 1, rotation: 0,resistanceValue: 330
            },
            { type: 'straight-wire', row: 1, col: 3, rotation: 90 },
            { type: 'star', row: 1, col: 2, rotation: 0 },
            { type: 'switch', row: 1, col: 1, rotation: 0 },
            { type: 'corner-wire', row: 0, col:4, rotation: 0 },
          ],
          draggableItems: [
            { type: "straight-wire", id: 0, itemCount: 1,rotation: 90 },
            { type: "corner-wire", id: 1,itemCount: 1,rotation: 180 },
            { type: "corner-wire", id: 2,itemCount: 1,rotation: 90 },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 5,
            gridRow: 2
        }
        },
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'straight-wire', row: 0, col: 3, rotation: 90 },
            { type: 'corner-wire', row: 0, col: 0, rotation: 270 },
            { type: "resistor",
              ...componentDictionary['resistor'].initialize(0),
              row: 0, col: 1, rotation: 0,resistanceValue: 330
            },
            { type: 'straight-wire', row: 1, col: 3, rotation: 90 },
            { type: 'star', row: 2, col: 1, rotation: 0 },
            { type: 'yellow-light-bulb', row: 1, col: 2, rotation: 90 },
            { 
              type: 'switch', 
              ...componentDictionary['switch'].initialize("ON"), 
              row: 1, col: 1, rotation: 0 
            },
            { 
              type: 'switch', 
              ...componentDictionary['switch'].initialize("OFF"), 
              row: 2, col: 3, rotation: 0 
            },
            { type: 'corner-wire', row: 0, col:4, rotation: 0 },
            { type: 'straight-wire', row: 2, col:2, rotation: 90 },
            { type: 't-wire', row: 1, col:4, rotation: 90 },
          ],
          draggableItems: [
            { type: "t-wire", id: 0, itemCount: 1,rotation: 270 },
            { type: "corner-wire", id: 1,itemCount: 1,rotation: 180 },
            { type: "green-light-bulb", id: 2,itemCount: 1,rotation: 180 },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 5,
            gridRow: 3
        }
        },
      ]},
  // Level 3 - Introduce resistor
    {exercises: [
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'straight-wire', row: 1, col: 1, rotation: 90 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: 'corner-wire', row: 1, col:0, rotation: 180 },
            { type: 'star', row: 1, col: 2, rotation: 0 },
            { type: 'corner-wire', row: 0, col:3, rotation: 0 },
            { type: 'corner-wire', row: 1, col:3, rotation: 90 },
          ],
          draggableItems: [
            { type: "straight-wire", id: 0, itemCount: 1,rotation: 90 },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(1),
              id: 1, itemCount: 1, rotation: 0,resistanceValue: 560
            },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(2), 
              id: 2, itemCount: 1, rotation: 0,resistanceValue: 330
            },
          ],
          goal:[
            { type: 'green-light-bulb', status: "ON" },
            { type: 'yellow-light-bulb', status: "OFF" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 4,
            gridRow: 2
        }
        },
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'straight-wire', row: 1, col: 1, rotation: 90 },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(1),
              row: 1, col: 1, rotation: 0,resistanceValue: 330
            },
            { type: 'straight-wire', row: 0, col: 1, rotation: 90 },
            { type: 'straight-wire', row: 0, col: 3, rotation: 90 },
            { type: 'corner-wire', row: 0, col: 0, rotation: 270 },
            { type: 't-wire', row: 1, col:0, rotation: 270 },
            { type: 'yellow-light-bulb', row: 1, col: 3, rotation: 90 },
            { type: 't-wire', row: 1, col: 2, rotation: 0 },
            { type: 'corner-wire', row: 0, col:4, rotation: 0 },
            { type: 'corner-wire', row: 1, col:4, rotation: 90 },
            { type: 'corner-wire', row: 2, col:0, rotation: 180 },
            { type: 'corner-wire', row: 2, col:2, rotation: 90 },
          ],
          draggableItems: [
            { type: "straight-wire", id: 0, itemCount: 1,rotation: 90 },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(1),
              id: 1, itemCount: 1, rotation: 0,resistanceValue: 560
            },
            { 
              type: "resistor",
              ...componentDictionary['resistor'].initialize(2), 
              id: 2, itemCount: 1, rotation: 0,resistanceValue: 330
            },
          ],
          goal:[
            { type: 'green-light-bulb', status: "OFF" },
            { type: 'yellow-light-bulb', status: "ON" },
            { type: 'red-light-bulb', status: "OFF" },
          ],
          metadata:{
            gridCol: 5,
            gridRow: 3
        }
        },
      ]},
    {exercises: [
        {
          prePlacedComponents: [
            { type: 'battery', row: 1, col: 2, rotation: 0 },
            { type: 'green-light-bulb', row: 1, col: 1, rotation: 0 },
            { type: 'yellow-light-bulb', row: 2, col: 2, rotation: 90 },
            { type: 'red-light-bulb', row: 1, col: 3, rotation: 90 },
            { type: 't-wire', row: 2, col: 1, rotation: 270 },
            { type: 'star', row: 3, col: 2, rotation: 0 },
          ],
          draggableItems: [
            { type: "t-wire", id: 0, itemCount: 1 },
            { type: "straight-wire", id: 1, itemCount: 1 },
            { type: "corner-wire", id: 2, itemCount: 2 },
          ],
          goal:[
            { type: 'green-light-bulb', status: true },
            { type: 'yellow-light-bulb', status: false },
            { type: 'red-light-bulb', status: true },
          ]
        },
        {
          prePlacedComponents: [
            { type: 'battery', row: 0, col: 2, rotation: 0 },
            { type: 'green-light-bulb', row: 0, col: 0, rotation: 0 },
            { type: 'straight-wire', row: 0, col: 1, rotation: 90 },
            { type: 'straight-wire', row: 1, col: 1, rotation: 90 },
            { type: 'star', row: 1, col: 2, rotation: 0 },
            { type: 'corner-wire', row: 0, col:4, rotation: 0 },
          ],
          draggableItems: [
            { type: "straight-wire", id: 0, itemCount: 2 },
            { type: "corner-wire", id: 1,itemCount: 2 },
            { type: "red-light-bulb", id: 2,itemCount: 1},
            { type: "yellow-light-bulb", id: 3,itemCount: 1 }
          ],
          goal:[
            { type: 'green-light-bulb', status: true },
            { type: 'yellow-light-bulb', status: true },
            { type: 'red-light-bulb', status: true },
          ]
        }
      ]}
  ];

  export default levels;