import React, { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import './DraggableItem.css';

const DraggableItem = ({ item, reset, remainingCount,placeInNextAvailableCell  }) => {
  const { type, connections, svgPath, id, rotation = 0,resistanceValue } = item; // Ensure id is destructured from item

  const handleTap = () => {
    if (remainingCount > 0) {
      placeInNextAvailableCell({ ...item, rotation }); 
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: 'ITEM',
    item: { type, connections, svgPath, id, rotation,resistanceValue  }, // Ensure id is included
    canDrag: () => {
      const canDrag = remainingCount > 0;
      if (canDrag) {
        console.log(`COUNT ${type} (${id}): ${remainingCount} remaining`);
      }
      return canDrag;
    },
    end: (item, monitor) => {
      if (!monitor.didDrop() || monitor.getDropResult()?.rejected) {
        console.log(`COUNT ${type} (${id}): drop rejected or not completed`);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (reset) {
      console.log(`COUNT ${type} (${id}): reset`);
    }
  }, [reset]);


  const itemStyle = {
    boxShadow: remainingCount === 0 ? '0 -5px #AFE5FF' : '',
    backgroundColor: remainingCount === 0 ? '#F5F5F5' : '',
    cursor: remainingCount === 0 ? 'not-allowed' : '',
    transform: remainingCount === 0 ? 'translateY(5px)' : '',
  };

  return (
    <div
      className={`draggable-item ${isDragging ? 'dragged' : ''}`}
      ref={drag}
      style={itemStyle}
      onClick={handleTap}
    >
      {remainingCount > 0 && (
        <>
          <img src={svgPath} alt={`${type}`} 
                      style={{
                        transform: `rotate(${rotation}deg)`, // Only rotate the image
                        transition: 'transform 0.2s ease', // Smooth transition for rotation
                      }}
          />
          {remainingCount > 1 && (
            <div className="item-count">x{remainingCount}</div>
          )}


          {/* Conditionally render the resistance value for resistors */}
          {type === 'resistor' && (
            <div className="resistance-value">
              {resistanceValue}Ω {/* Display the resistance with the Ohms symbol */}
            </div>
          )}

        </>
      )}
    </div>
  );
};

export default DraggableItem;
