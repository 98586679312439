import React, { useState } from 'react';

const TestPage = () => {
  const [responseMessage, setResponseMessage] = useState('');

  const sendTestRequest = () => {
    fetch('https://playmicro.pythonanywhere.com/test', {  // Use your live backend URL here
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name: 'Test User' })
    })
      .then(response => response.json())
      .then(data => {
        setResponseMessage(data.message);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <div>
      <h1>Backend Communication Test</h1>
      <button onClick={sendTestRequest}>Send Test Request</button>
      <p>{responseMessage}</p>
    </div>
  );
};

export default TestPage;
