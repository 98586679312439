import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { LevelContext } from '../contexts/LevelContext';
import './HomePage.css';
import CircuitBoard from './CircuitBoard';
import levels from './Levels.js';

const chapters = [
    { title: "Chapter 1:", description: "Batteries, resistors and switches", type: 'beginner' },
    { title: "Chapter 2:", description: "Using schematic symbols", type: 'advanced' }
  ];

const HomePage = () => {
  const navigate = useNavigate();
  const { levelCompletion } = useContext(LevelContext);
  const { levelCompletionAdv } = useContext(LevelContext);
  const [selectedBoard, setSelectedBoard] = useState(0); // Add state to toggle between beginner and advanced boards

  const goToLevel = (levelIndex, exerciseIndex = 0) => {
    navigate(`/level/${levelIndex}/exercise/${exerciseIndex}`);
  };

  // Create a function to update the SVG elements
  const updateLevelStatus = useCallback(() => {
    // Wire color, star updates, etc. for advanced levels
    levelCompletionAdv.forEach((isComplete, index) => {
      const levelNumber = index + 1;
      const strokeColor = isComplete.completed ? "#FFC935" : "#AFE5FF";
      const WirestrokeColor = isComplete.completed ? "#FFC300" : "#70A187";
      const filterId = isComplete.completed ? "filter-complete" : "filter-incomplete";

      const levelGroup = document.querySelector(`#advanced-circuitboard #Level-${levelNumber}`);

      if (levelGroup && levelNumber !== 5) {
        const rectGroup = levelGroup.querySelector(`g[id^='Rectangle']`);
        if (rectGroup) {
          rectGroup.setAttribute("filter", `url(#${filterId})`);
          const rectElement = rectGroup.querySelector("rect:last-of-type");
          if (rectElement) {
            rectElement.setAttribute("stroke", strokeColor);
          }
        }

        const num = levelGroup.querySelector(`path[id='${levelNumber}']`);
        if (num) {
          num.setAttribute("fill", strokeColor);
        }

        const wirePaths = document.querySelectorAll(`#advanced-circuitboard path[id^='Level-${levelNumber}-']`);
        wirePaths.forEach(path => {
          path.setAttribute("stroke", WirestrokeColor);
        });

        const bonusPaths = document.querySelectorAll(`#advanced-circuitboard #Level-${levelNumber}-Bonus path`);
        bonusPaths.forEach(path => {
          path.setAttribute("stroke", WirestrokeColor);
        });

        const additionalPaths = document.querySelectorAll(`#advanced-circuitboard [id*='Level-${levelNumber}-']`);
        additionalPaths.forEach(path => {
          if (path.tagName === 'path') {
            path.setAttribute("stroke", WirestrokeColor);
          }
        });
      }

      // Show/hide radial gradient for bonus level
      const bonusLevelGroup = document.querySelector("#advanced-circuitboard #Bonus-level");
      if (bonusLevelGroup) {
        const radialGradientCircle = bonusLevelGroup.querySelector("#Ellipse\\ 215 path");
        if (radialGradientCircle) {
          if (levelCompletionAdv[1].completed && levelCompletionAdv[2].completed && levelCompletionAdv[3].completed) {
            radialGradientCircle.style.display = "block";
            bonusLevelGroup.classList.add("clickable");
            bonusLevelGroup.addEventListener("click", () => goToLevel(4));
          } else {
            radialGradientCircle.style.display = "none";
            bonusLevelGroup.classList.remove("clickable");
            bonusLevelGroup.removeEventListener("click", () => goToLevel(4));
          }
        }
      }
    });

    // Wire color, star updates, etc. for beginner levels
    levelCompletion.forEach((isComplete, index) => {
      const levelNumber = index + 1;
      const strokeColor = isComplete.completed ? "#FFC935" : "#AFE5FF";
      const WirestrokeColor = isComplete.completed ? "#FFC300" : "#70A187";
      const filterId = isComplete.completed ? "filter-complete" : "filter-incomplete";

      const levelGroup = document.querySelector(`#beginner-circuitboard #Level-${levelNumber}`);

      if (levelGroup && levelNumber !== 5) {
        const rectGroup = levelGroup.querySelector(`g[id^='Rectangle']`);
        if (rectGroup) {
            const filterExists = document.querySelector(`#${filterId}`);
            if (filterExists) {
              rectGroup.setAttribute("filter", `url(#${filterId})`);
            } else {
              console.error(`Filter #${filterId} not found in DOM`);
            }
          const rectElement = rectGroup.querySelector("rect:last-of-type");
          if (rectElement) {
            rectElement.setAttribute("stroke", strokeColor);
          }
        }

        const num = levelGroup.querySelector(`path[id='${levelNumber}']`);
        if (num) {
          num.setAttribute("fill", strokeColor);
        }

        const wirePaths = document.querySelectorAll(`#beginner-circuitboard path[id^='Level-${levelNumber}-']`);
        wirePaths.forEach(path => {
          path.setAttribute("stroke", WirestrokeColor);
        });

        const bonusPaths = document.querySelectorAll(`#beginner-circuitboard #Level-${levelNumber}-Bonus path`);
        bonusPaths.forEach(path => {
          path.setAttribute("stroke", WirestrokeColor);
        });

        const additionalPaths = document.querySelectorAll(`#beginner-circuitboard path[id*='Level-${levelNumber}-']`);
        additionalPaths.forEach(path => {
          if (path.tagName === 'path') {
            path.setAttribute("stroke", WirestrokeColor);
          }
        });
      }

      const starElement = document.getElementById(`Star-${levelNumber}`);
      if (starElement) {
        const starFilter = isComplete.starAchieved ? `url(#filter${6 + index}_d_450_270)` : `url(#filter${11 + index}_d_450_270)`;
        const starFill = isComplete.starAchieved ? "#FFC300" : "#565656";
        const starStroke = isComplete.starAchieved ? "#FFB020" : "#3B3B3B";
        const starPath = starElement.querySelector("path:first-of-type");
        const starOutline = starElement.querySelector("path:last-of-type");

        if (starPath) {
          starPath.setAttribute("fill", starFill);
        }
        if (starOutline) {
          starOutline.setAttribute("stroke", starStroke);
        }
        starElement.setAttribute("filter", starFilter);
      }

      const bonusLevelGroup = document.querySelector("#beginner-circuitboard #Bonus-Level");
      if (bonusLevelGroup) {
        const radialGradientCircle = bonusLevelGroup.querySelector("circle");
        if (radialGradientCircle) {
          if (levelCompletion[0].completed && levelCompletion[1].completed && levelCompletion[2].completed) {
            radialGradientCircle.style.display = "block";
            bonusLevelGroup.classList.add("clickable");
            bonusLevelGroup.addEventListener("click", () => goToLevel(4));
          } else {
            radialGradientCircle.style.display = "none";
            bonusLevelGroup.classList.remove("clickable");
            bonusLevelGroup.removeEventListener("click", () => goToLevel(4));
          }
        }
      }
    });
  }, [levelCompletion, levelCompletionAdv, goToLevel]);

  // UseEffect to run the updates based on levelCompletion and levelCompletionAdv
  useEffect(() => {
    updateLevelStatus(); // Runs every time levelCompletion or levelCompletionAdv changes
  }, [levelCompletion, levelCompletionAdv, updateLevelStatus]);

  // UseEffect that runs when the SVG is loaded
  const onSVGLoaded = () => {
    updateLevelStatus(); // Ensure initial SVG manipulation after it's loaded
  };

  const handleStartLearning = () => {
    const randomLevelIndex = Math.floor(Math.random() * levels.length); // Select random level index
    const selectedLevel = levels[randomLevelIndex];
    const randomExerciseIndex = Math.floor(Math.random() * selectedLevel.exercises.length); // Select random exercise index
    
    // Navigate to the selected random level and exercise
    goToLevel(randomLevelIndex, randomExerciseIndex);
  };


  const toggleChapter = () => {
    setSelectedBoard((prev) => (prev === 0 ? 1 : 0)); // Toggle between chapters
  };


  return (
    <>


      <div className="header-box">
        <h1>playmicro</h1>
      </div>
      <div className="main-homepage-wrapper">
        <div className="main-section">
      <div className="title-container">
        <h3>{chapters[selectedBoard].title}</h3>
        <p>{chapters[selectedBoard].description}</p>
      </div>

        {/* Circuit Board Section */}
        <div className="circuit-board-wrapper">
        <CircuitBoard
            type={chapters[selectedBoard].type} // Use the "type" from chapters array (beginner or advanced)
            goToLevel={goToLevel}
            onSVGLoaded={updateLevelStatus}
        />
        </div>

                  {/* Toggle Switch 
        <div className="toggle-wrapper">
            <input type="checkbox" id="toggle" className="toggleCheckbox" checked={selectedBoard === 1} onChange={toggleChapter} />
            <label htmlFor="toggle" className="toggleContainer">
              <div>Chapter 1</div>
              <div>Chapter 2</div>
            </label>
          </div>*/}

      <div className="button-container">
        <h3>Daily Challenge</h3>
        <button className="play-button" onClick={handleStartLearning}>
          Try it
        </button>
      </div>
      </div>
      </div>
    </>
  );
};

export default HomePage;
