import React, { useEffect, useRef } from 'react';

const CircuitBoard = ({ type, goToLevel, onSVGLoaded }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    // Determine the correct SVG file based on the `type` prop
    const svgFile = type === 'beginner'
      ? '/beginner-circuitboard.svg'
      : '/advanced-circuitboard.svg';

    // Fetch the SVG file content and insert it into the div
    fetch(svgFile)
      .then(response => response.text())
      .then(svgContent => {
        if (svgRef.current) {
          svgRef.current.innerHTML = svgContent;
          addClickHandlers();  // Add click handlers after SVG is rendered

          // Call the callback to notify the parent that the SVG is loaded
          if (onSVGLoaded) {
            onSVGLoaded();
          }
        }
      })
      .catch(error => {
        console.error('Error loading SVG:', error);
      });
  }, [type, onSVGLoaded]);

  const addClickHandlers = () => {
    const levels = [1, 2, 3, 4]; // Adjust this array based on your levels

    levels.forEach(level => {
      const levelElement = svgRef.current.querySelector(`#Level-${level}`);
      if (levelElement) {
        levelElement.addEventListener('click', () => goToLevel(level - 1));
      }
    });
  };

  return <div ref={svgRef} />;
};

export default CircuitBoard;
