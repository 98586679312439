// src/components/MainHomePage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MainHomePage.css'; // Import your CSS file
import HomePageImg from '../assets/HomePageImg.png'; // Import the image
import BearImg from '../assets/BearImg.png'; // Import the image

const MainHomePage = () => {
  const navigate = useNavigate();

  const handleStartLearning = () => {
    navigate('/learn'); // Navigate to the learning page (current HomePage.js)
  };

  return (
    <>
    <div className='MainHomePage-Body'>
      {/* Header */}
      <div className="header-box">
        <h1>playmicro</h1>
      </div>

      {/* Main content wrapper */}
      <div className="main-homepage-wrapper">
        <div className="main-section">
            <div className="ImgWrapper">
                <img
                    src={HomePageImg} // Display banner image
                    alt="Main Circuit Banner"
                    className="banner-image"
                />
                <h2>A fun, accessible and effective way to learn electronics</h2>
            </div>
          {/* Container for the text and button */}
          <div className="button-container">
            <h3>No account needed, try it</h3>
            <button className="play-button" onClick={handleStartLearning}>
              Start Learning
            </button>
          </div>
        </div>

        <div className="section">
            <div className="title-container">
                <h3>About us</h3>
                <p>Who are we?</p>
            </div>
            <div className="content">
                <p>Playmicro is a platform focused on making learning electronics accessible, fun and effective.</p>
                <p>This is achieved by breaking key principles down into small chunks that learners can engage with daily.</p>
                <p>By setting up the exercise like a puzzle game, learners are prompted to explore and be curious about how to achieve the set out goal – with the game mechanics being electronic principles.</p>
                <p>Thus, through pattern recognition, learners pick up the core principles of circuits.</p>
            </div>
            <div className="section-image">
                <img src={BearImg} alt="Playmicro character" />
            </div>
        </div>
        <div className="section">
            <div className="title-container">
                <h3>Partner with us!</h3>
                <p>Lets take on the employment gap</p>
            </div>
            <div className="content">
                <p>We are looking for schools, charities and company's to partner with to focus on 3 key areas:</p>
                <li>Schools: Help guide the content and improve student engagement. Lets work together to deploy this app into schools and get students into electronics</li>

                <li>Companies: Help drive this initiative by providing the backing and content to ensure we are teaching industry relevant content</li>

                <li>Charities: Support pushing this in front of schools and sponsors to help bring this initiative to life</li>

                <p>Get in touch by emailing:</p>
                <p style={{color:"#1FB6FF"}}>Jackdaly@playmicrocade.com</p>
            </div>
        </div>

      </div>
      </div>
    </>
  );
};

export default MainHomePage;