import componentDictionary from './components/componentDictionary';

const directions = {
  top: [-1, 0],
  bottom: [1, 0],
  left: [0, -1],
  right: [0, 1],
};

const getOppositeDirection = (direction) => {
  const opposite = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
  };
  return opposite[direction];
};

const isValidMove = (row, col, grid) => {
  return row >= 0 && col >= 0 && row < grid.length && col < grid[0].length;
};

const findPath = (start, bulb, end, grid) => {
  if (!start || !end || !grid || !bulb) {
    console.error('Invalid input parameters');
    return { foundPath: false, path: [] };
  }

  const stack = [{ ...start, pathBulb: false || (start.row === bulb.row && start.col === bulb.col), path: [] }];
  const visited = new Set();

  const BattPos = findComponentPosition('battery', grid);

  const importantVisited = new Set(); // Store important visited values

  switch (grid[BattPos.row][BattPos.col].rotation) {
    case 90:
      importantVisited.add(`${start.row+1}-${start.col}`);
      break;
    case 180:
      importantVisited.add(`${start.row}-${start.col-1}`);
      break;
    case 270:
      importantVisited.add(`${start.row-1}-${start.col}`);
      break;
    case 0: 
    default:
      importantVisited.add(`${start.row}-${start.col+1}`);
      //console.log('default:', start.row, start.col-1);
  }

  importantVisited.add(`${start.row}-${start.col}`);
  visited.add(`${start.row}-${start.col}`);

  let foundPath = false;
  let validPath = [];
  const paths = [];

  while (stack.length) {
    const { row, col, pathBulb, path } = stack.pop();
    //console.log('Stack pop:', row, col, pathBulb, path);
    const current = grid[row]?.[col];

    if (!current) {
      console.warn(`Current component at (${row}, ${col}) is null or undefined`);
      continue;
    }

    const currentPath = [...path, { row, col, type: current.type }];
    //console.log('currentPath:', currentPath);

    // Adjust visited to match the current path and preserve important values
    visited.clear();
    importantVisited.forEach(value => visited.add(value));
    currentPath.forEach(({ row, col }) => {
      visited.add(`${row}-${col}`);
    });

    if (current.type === 'battery' || currentPath[currentPath.length - 1].row === end.row && currentPath[currentPath.length - 1].col === end.col) {
      visited.delete(`${end.row}-${end.col}`);
      console.log('END REACHED, path:', pathBulb);
      if (pathBulb) {
        //console.log('End reached with a bulb in path.');
        paths.push(currentPath);
        foundPath = true;
        validPath = currentPath;  // Save the valid path
      }else {
        //console.log('End reached without a bulb in path.');
      }
      continue;
    }

    for (let direction in current.connections) {
      if (current.connections[direction]) {
        const [dx, dy] = directions[direction];
        const newRow = row + dx;
        const newCol = col + dy;

        //console.log('Attempting move:', { direction, newRow, newCol });

        if (isValidMove(newRow, newCol, grid)) { //is next move valid
          const next = grid[newRow]?.[newCol];
          //console.log('next:', next);
          //console.log('Connection valid:', { from: { row, col }, to: { newRow, newCol } });
          if(next){
            if (newRow === end.row && newCol === end.col) { //is next move the end? (next.type == 'battery'){//
              visited.delete(`${newRow}-${newCol}`);
              //console.log('Attempting to move into end, visisted deleted');
              //then delete the end from visited
            }
          }

          if(!visited.has(`${newRow}-${newCol}`)){ //check visisted
            //console.log('Not visited:', { from: { row, col }, to: { newRow, newCol } });
            if (next && next.connections[getOppositeDirection(direction)]) {
              stack.push({
                row: newRow,
                col: newCol,
                pathBulb: pathBulb || (next.type === bulb.type && newRow === bulb.row && newCol === bulb.col),
                path: currentPath
              });
              //console.log('pathBulb:', pathBulb || (next.type === bulb.type && newRow === bulb.row && newCol === bulb.col));
              visited.add(`${newRow}-${newCol}`);
            }else{
              //console.log('Connection invalid (next):', { from: { row, col }, to: { newRow, newCol } });
            }
          }else {
            //console.log('Connection invalid (visited):', { from: { row, col }, to: { newRow, newCol } });
          }

        }else {
          // console.log('Invalid move:', { newRow, newCol });
        }

      }
    }
  }

  // console.log('END - Visited:', visited);
  // console.log('Paths:', paths);
  return { foundPath, path: validPath };
};

const findComponentPosition = (componentType, grid) => {
  for (let row = 0; row < grid.length; row++) {
    for (let col = 0; col < grid[row].length; col++) {
      if (grid[row][col] && grid[row][col].type === componentType) {
        return { row, col, type: componentType };
      }
    }
  }
  return null;
};

const areComponentsConnected = (component, batteryConnections) => {
  for (const direction in component.connections) {
    if (component.connections[direction] && batteryConnections[getOppositeDirection(direction)]) {
      return true;
    }
  }
  return false;
};

const getStartEndComponents = (batteryPosition, grid) => {
  if (!batteryPosition || !grid) {
    console.error('Invalid battery position or grid');
    return { start: null, end: null };
  }

  let start, end;
  switch (grid[batteryPosition.row][batteryPosition.col].rotation) {
    case 90:
      start = { row: batteryPosition.row - 1, col: batteryPosition.col };
      end = { row: batteryPosition.row + 1, col: batteryPosition.col };
      break;
    case 180:
      start = { row: batteryPosition.row, col: batteryPosition.col + 1 };
      end = { row: batteryPosition.row, col: batteryPosition.col - 1 };
      break;
    case 270:
      start = { row: batteryPosition.row + 1, col: batteryPosition.col };
      end = { row: batteryPosition.row - 1, col: batteryPosition.col };
      break;
    case 0: // 0 or any other value defaults to 0
    default:
      start = { row: batteryPosition.row, col: batteryPosition.col - 1 };
      end = { row: batteryPosition.row, col: batteryPosition.col + 1 };
  }


  const batteryConnections = grid[batteryPosition.row][batteryPosition.col].connections;
  const startComponent = grid[start.row]?.[start.col];
  const endComponent = grid[end.row]?.[end.col];
  
  if (!startComponent || !endComponent) {
    console.error('Start and/or end component is missing');
    return { start: null, end: null };
  }
  
  //console.log('Battery:', batteryPosition);
  //console.log('startComponent:', startComponent);
  //console.log('endComponent:', endComponent);
  
  // Ensure start and end components are connected to the battery
  let startConnected = false;
  let endConnected = false;

  for (const direction in batteryConnections) {
    if (batteryConnections[direction]) {
      const [dx, dy] = directions[direction];
      const batteryNeighborRow = batteryPosition.row + dx;
      const batteryNeighborCol = batteryPosition.col + dy;
      
      if (isValidMove(batteryNeighborRow, batteryNeighborCol, grid)) {
        const neighborComponent = grid[batteryNeighborRow][batteryNeighborCol];
        if (neighborComponent) {
          if (neighborComponent === startComponent && neighborComponent.connections[getOppositeDirection(direction)]) {
            startConnected = true;
          }
          if (neighborComponent === endComponent && neighborComponent.connections[getOppositeDirection(direction)]) {
            endConnected = true;
          }
        }
      }
    }
  }

  if (!startConnected || !endConnected) {
    console.error('Start and/or end components are not connected to the battery');
    return { start: null, end: null };
  }
  

  return { start, end };
};


const calculateCurrent = (voltage, resistance) => {
  return voltage / resistance; // Ohm's law I = V / R
};

const determineBulbState = (current, bulb) => {
  const { minCurrentForOn, minCurrentForDim, explodeThreshold } = bulb;

  console.log('Current:', current);
  console.log('explodeThreshold:', explodeThreshold);
  console.log('minCurrentForOn:', minCurrentForOn);
  console.log('minCurrentForDim:', minCurrentForDim);

  if (current >= explodeThreshold) {
    console.log('BulbStatus: EXPLODED');
    return "EXPLODED";
  } else if (current >= minCurrentForOn) {
    console.log('BulbStatus: ON');
    return "ON";
  } else if (current >= minCurrentForDim) {
    console.log('BulbStatus: DIM');
    return "DIM";
  } else {
    console.log('BulbStatus: OFF');
    return "OFF";
  }
};



const calculateTotalResistanceAlongPath = (path, grid) => {
  let totalResistance = 0;

  // Iterate over the pre-calculated path
  if (path) {
    path.forEach(({ row, col }) => {
      const component = grid[row][col];
      if (component && component.type) {
        // If it's a resistor, add its resistance to the total resistance
        if (component.type === 'resistor') {
          totalResistance += component.resistanceValue;
        }

        // If it's a bulb, add its resistance to the total resistance
        if (component.type.includes('light-bulb')) {
          const bulbResistance = componentDictionary[component.type].resistanceValue;
          totalResistance += bulbResistance;
        }
        
        if (component.type.includes('wire')) {
          totalResistance += 0.1;
        }
      }
    });
  }

  console.log('Total resistance:', totalResistance);
  return totalResistance > 0 ? totalResistance : 1; // Ensure we don't divide by zero
};




const updateBulbStatus = (grid, setBulbStatus,setStarStatus) => {
  if (!grid || !setBulbStatus) {
    console.error('Invalid input parameters');
    return;
  }

  const newBulbStatus = {
    'red-light-bulb': "OFF",
    'green-light-bulb': "OFF",
    'yellow-light-bulb': "OFF",
  };

  let newStarStatus = false;
  const batteryPosition = findComponentPosition('battery', grid);

  if (!batteryPosition) {
    console.error('Battery component not found in the grid');
    setBulbStatus(newBulbStatus);
    setStarStatus(newStarStatus);
    return;
  }

  const voltage = 9; // Assuming a 9V battery
  const { start, end } = getStartEndComponents(batteryPosition, grid);

  // console.log('Start:', start);
  // console.log('End:', end);
  // console.log('Rotation:', grid[batteryPosition.row][batteryPosition.col].rotation);

  Object.keys(newBulbStatus).forEach(bulbType => {
    const bulbPosition = findComponentPosition(bulbType, grid);
  
    if (bulbPosition) {
      const { foundPath, path } = findPath(start, bulbPosition, end, grid);
      if (foundPath) {
        console.log("Path found for bulb:", bulbType);
        console.log('Path:', path);
        // Now you can use the path to calculate the total resistance
        const totalResistance = calculateTotalResistanceAlongPath(path, grid);
        const current = calculateCurrent(voltage, totalResistance);
  
        const bulb = componentDictionary[bulbType];
        newBulbStatus[bulbType] = determineBulbState(current, bulb); // Set bulb state (OFF, DIM, ON, EXPLODED)
      }
    }
  });
  

    // console.log('End of bulbs: -------');

    const starPosition = findComponentPosition('star', grid);
    if (starPosition) {
      // console.log('Checking STAR: -------', starPosition);
      const { foundPath: starFoundPath } = findPath(start, starPosition, end, grid);
    newStarStatus = starFoundPath;  // Use the actual `foundPath` boolean
  }
    setStarStatus(newStarStatus);
    console.log('Star status updated:', newStarStatus);

  setBulbStatus(newBulbStatus);
  console.log('Bulb status updated:', newBulbStatus);
};

export default updateBulbStatus;
